$general-color: #ffd437;
$text-color: #000000;
$secondary-color: #65d7cb;
$gray-color: #f3f3f3;
$outline-color: #d9d9d9;
$secondary-base-color: #a6b8ef;
$base-color: #ffffff;

$blue-color: #a6b8ef;
$black-color: #000000;
$error-color: #f04438;
$done-color: #19c98a;
$warning-color: #ffc107;
$placeholder-color: #939393;
