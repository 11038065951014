@use '@angular/material' as mat;

@include mat.core();

$admin-kakoo-accent: mat.define-palette((
  50: #ffe081,
  100: #ffd437,
  200: #ffcf33,
  300: #ffc92f,
  400: #ffc329,
  500: #ffbd23,
  600: #ffb71d,
  700: #ffb117,
  800: #ffab11,
  900: #ffa50b,
  A100: #ffd437,
  A200: #ffd437,
  A400: #ffd437,
  A700: #ffd437,
  contrast: (
    50: $black-color,
    100: $black-color,
    200: $black-color,
    300: $black-color,
    400: $black-color,
    500: $black-color,
    600: $black-color,
    700: $black-color,
    800: $black-color,
    900: $black-color,
    A100: $black-color,
    A200: $black-color,
    A400: $black-color,
    A700: $black-color,
  )
));

$admin-kakoo-primary: mat.define-palette((
  50: #e5f8f7,
  100: #b2f0ec,
  200: #80e8e2,
  300: #4de0d7,
  400: #1ad8cc,
  500: #00d0c1,
  600: #00a89a,
  700: #007073,
  800: #004a4c,
  900: #002325,
  A100: #b2f0ec,
  A200: #80e8e2,
  A400: #4de0d7,
  A700: #1ad8cc,
  contrast: (
    50: $black-color,
    100: $black-color,
    200: $black-color,
    300: $black-color,
    400: $black-color,
    500: $black-color,
    600: $black-color,
    700: $black-color,
    800: $black-color,
    900: $black-color,
    A100: $black-color,
    A200: $black-color,
    A400: $black-color,
    A700: $black-color,
  )
));

$admin-kakoo-warn: mat.define-palette((
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: $error-color,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (
    50: $black-color,
    100: $black-color,
    200: $black-color,
    300: $black-color,
    400: $black-color,
    500: $black-color,
    600: $black-color,
    700: $black-color,
    800: $black-color,
    900: $black-color,
    A100: $black-color,
    A200: $black-color,
    A400: $black-color,
    A700: $black-color,
  )
));

$admin-kakoo-theme: mat.define-light-theme((
  color: (
    primary: $admin-kakoo-primary,
    accent: $admin-kakoo-accent,
    warn: $admin-kakoo-warn,
  ),
  density: 0,
));

@include mat.all-component-themes($admin-kakoo-theme);

.mat-mdc-select {
  font-family: IBM, sans-serif !important;
}
