@import "../variables/variables.scss";

@font-face {
  font-family: "IBM";
  src: url("../../assets/fonts/IBMPlexSansCondensed-Regular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "IBM";
  src: url("../../assets/fonts/IBMPlexSansCondensed-Medium.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "IBM";
  src: url("../../assets/fonts/IBMPlexSansCondensed-SemiBold.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../../assets/fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

// .numbers-fonts {
//   font-family: Albert Sans;
//   font-size: 64px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 112.5%;
// }

// .headline0 {
//   font-family: Albert Sans;
//   font-size: 48px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 116.667%;
// }

// .headline1 {
//   font-family: Albert Sans;
//   font-size: 40px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 160%;
// }

// .headline2 {
//   font-family: Albert Sans;
//   font-size: 32px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 150%;
// }

// .headline3 {
//   font-family: Albert Sans;
//   font-size: 24px;
//   font-style: normal;
//   font-weight: 600;
//   line-height: 166.667%;
// }

// .headline4 {
//   font-family: Albert Sans;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 600;
//   line-height: 150%;
// }

// .btn-fonts {
//   font-family: Albert Sans;
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 600;
//   line-height: 171.429%;
// }

// .subtitle {
//   font-family: General Sans;
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 171.429%;
// }

// .link-fonts {
//   color: var(--Text, #242427);
//   font-family: General Sans;
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 171.429%;
//   text-decoration-line: underline;
// }

// .label {
//   font-family: General Sans;
//   font-size: 12px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 133.333%;
// }

// .second-label {
//   font-family: General Sans;
//   font-size: 12px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 133.333%;
// }

body {
  color: var(--Text, #242427);
  font-family: IBM;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

// .adaptive-h-fonts {
//   font-family: Albert Sans;
//   font-size: 72px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 100%;
//   @media (max-width: $tablet-width) {
//     font-size: calc(32px + (40 + 40 * 0.7) * ((100vw - 375px) / 1440));
//   }
// }
