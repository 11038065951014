.table {
  width: calc(100% - 4px);
  border-collapse: separate;
  border-spacing: 0;
  border: 2px solid $black-color;
  border-radius: 8px;
  box-shadow: 4px 4px 0px #000;
  background-color: $gray-color;
  font-size: 16px;

  thead {
    background: inherit;
    background-color: $general-color;
    border-bottom: none;
    white-space: nowrap;

    tr:first-child th:first-child {
      border-top-left-radius: 8px;
    }

    tr:first-child th:last-child {
      border-top-right-radius: 8px;
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 8px;
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: 8px;
    }

    th {
      border-bottom: 2px solid $black-color;
    }
  }

  .table-row {
    text-align: left;
  }

  .table-header-item,
  .table-body-item {
    padding: 0.75rem 1rem;
    align-content: center;
  }

  .table-header-item {
    font-weight: 600;
  }

  .table-span,
  .table-body-item {
    white-space: nowrap;
  }
}

.table-btn-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;

  .table-btn {
    background-color: $general-color;
    border: 2px solid $black-color;
    box-shadow: 4px 4px 0px #000;
    border-radius: 6px;
    height: 30px;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.1s ease;
    padding: 0 0.5rem;

    &:active {
      transform: translate(4px, 4px);
      box-shadow: 0px 0px 0px #000;
    }
  }
}

.table-name {
  font-size: 22px;
  font-weight: 500;
}

.table-wrapper {
  max-width: 100%;
  overflow-x: auto;
  padding-bottom: 8px;
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    width: 0.375rem;
    height: 375rem;
    border-radius: 6px;
    background: $general-color;
  }

  &::-webkit-scrollbar-button:vertical:increment,
  &::-webkit-scrollbar-button:vertical:decrement {
    height: 2px !important;
  }
}

.table-empty {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  padding: 2rem 0;
}

.money-wrapper {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  .money-img {
    height: 20px;
  }
}

.border-row:not(:last-child) {
  .table-body-item {
    border-bottom: 2px dashed $general-color;
  }
}

.sortable {
  display: flex;
  gap: 0.2rem;
  align-items: center;
  cursor: pointer;
  .sortable-img {
    width: 16px;
  }
}
