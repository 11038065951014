@import "../../variables/variables.scss";

.popup-content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: #fff;
  border-radius: 8px;
  border: 2px solid $black-color;

  .btn-wrapper {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;

    .btn {
      height: 38px;
      color: #fff;
      font-size: 18px;
      padding: 0 0.5rem;
    }

    .close-btn {
      border-color: #fff;
      background-color: #000;
    }

    .delete-btn {
      background-color: $error-color;
    }
  }

  .popup-title {
    font-size: 24px;
    text-align: center;
    font-weight: 600;
  }

  .popup-info {
    font-size: 20px;
    text-align: center;
  }
}

.form-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  .form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .input {
      height: 42px;
    }

    .input-wrapper {
      position: relative;

      .input {
        padding-right: 35px;
        width: 100%;
      }

      .money-img {
        position: absolute;
        right: 15px;
        top: 10px;
      }
    }

    .error-block {
      color: $error-color;
      padding-left: 0.2rem;
    }
  }

  .btn-wrapper {
    grid-column: span 2;
    margin-right: 0.5rem;
  }
}

.popup-content {
  position: relative;

  .x-mark-btn {
    border: none;
    background: transparent;
    padding: 0;
    position: absolute;
    width: 15px;
    height: 15px;
    top: 15px;
    right: 20px;
    cursor: pointer;
  }

  .x-mark-btn::before,
  .x-mark-btn::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: black;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .x-mark-btn::before {
    transform: translateX(-50%) rotate(45deg);
  }

  .x-mark-btn::after {
    transform: translateX(-50%) rotate(-45deg);
  }
}
