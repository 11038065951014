@import "../../../styles/variables/variables.scss";

.btn {
  background-color: $general-color;
  border: 2px solid $black-color;
  box-shadow: 4px 4px 0px #000;
  border-radius: 8px;
  height: 60px;
  font-weight: 500;
  font-size: 22px;
  cursor: pointer;
  transition: all 0.1s ease;

  &:active {
    transform: translate(4px, 4px);
    box-shadow: 0px 0px 0px #000;
  }
}

.btn-disabled {
  cursor: not-allowed;
  &:active {
    transform: none;
    box-shadow: 4px 4px 0px #000;
  }
}

.error-btn {
  background-color: $error-color !important;
}
