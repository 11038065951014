@import "../../../styles/variables/variables.scss";

.input {
  border-radius: 8px;
  border: 2px solid $black-color;
  background: #fff;
  height: 48px;
  padding-left: 1rem;
  padding-right: 1rem;
  outline: none;
  transition: all 0.3s ease;
  font-family: IBM;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  &::placeholder {
    color: $placeholder-color;
    font-feature-settings: "clig" off, "liga" off;
  }

  &:focus {
    border: 2px solid $general-color;
  }

  &:invalid {
    border: 1px solid $error-color;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.88);
  }

  &:disabled {
    border: 1px solid $gray-color;
  }
}

.error-field {
  border-color: $error-color;
}

.error-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: $error-color;
  font-size: 14px;
}

.sort-wrapper {
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
}

@media (max-width: $tablet-width) {
  .sort-wrapper {
    gap: 1rem;

    .custom-select {
      width: calc(50% - 0.5rem);
    }
  }
}

.search-wrapper {
  position: relative;

  .search-results {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
  }

  .search {
    width: 100%;
  }

  .error-wrapper {
    text-align: center;
    padding: 1rem;
    color: $text-color;
  }
}
