@import "../../variables/variables.scss";

.date-btn {
  height: 36px;
  font-size: 20px;
  margin-top: 0.5rem;
  background-color: $secondary-color;
}

.custom-datepicker {
  height: 48px;

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 22px;
  }

  .mat-mdc-form-field-flex {
    display: flex;
    flex-direction: row;
    .mat-mdc-form-field-infix {
      order: 2;
    }

    .mat-mdc-form-field-icon-suffix {
      order: 1;
    }
  }

  .mdc-text-field,
  .mat-mdc-form-field-icon-suffix,
  [dir="rtl"] .mat-mdc-form-field-icon-prefix {
    padding-left: 0;
  }

  .mdc-floating-label--float-above {
    display: none;
  }

  .mat-mdc-form-field-required-marker {
    display: none !important;
  }

  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
    .mat-mdc-form-field-infix {
    padding-top: 10px !important;
    padding-bottom: 3px !important;
  }

  .mat-mdc-form-field-infix {
    min-height: auto !important;
  }
}

.mat-calendar-body-selected.mat-calendar-body-today {
  box-shadow: none !important;
}
