@import "./styles/reset.scss";
@import "./styles/variables/variables.scss";
@import "./styles/components/components.scss";
@import "./styles/typography/typography.scss";
@import "./styles/material.scss";

html, body {
  height: 100%;
  margin: 0;
}

.page-wrapper {
  padding: 0 1rem;
  margin-top: 3rem;
  padding-bottom: 2rem;
}

@media (max-width: $mobile-width) {
  .page-wrapper {
    margin-top: 2rem;
  }
}
